// eslint globals
/* global Swiper */
document.addEventListener('DOMContentLoaded', function () {


  // drop open and hide when click outside
  const links = document.querySelectorAll('.js-dropdown-link');
  const innerElements = document.querySelectorAll('.js-dropdown');

  function handleClick(e) {
    const targetElement = document.getElementById(this.hash.substr(1));
    const isAlreadyActive = this.classList.contains('active');
    links.forEach(link => link.classList.remove('active'));
    innerElements.forEach(el => el.classList.remove('open'));
    if (!isAlreadyActive) {
      this.classList.add('active');
      targetElement.classList.add('open');
    }
    e.preventDefault();
  }

  function handleMouseUpTouchStartKeyUp(e) {
    const isInsideDropdown = Array.from(links).concat(Array.from(innerElements)).some(el => el.contains(e.target));
    if (!isInsideDropdown || e.keyCode === 27) {
      links.forEach(link => link.classList.remove('active'));
      innerElements.forEach(el => el.classList.remove('open'));
    }
  }

  links.forEach(link => link.addEventListener('click', handleClick));
  document.addEventListener('mouseup', handleMouseUpTouchStartKeyUp);
  document.addEventListener('touchstart', handleMouseUpTouchStartKeyUp);
  document.addEventListener('keyup', handleMouseUpTouchStartKeyUp);


  // toggle
  const toggle = function (linksSelector, panelsSelector) {
    const links = document.querySelectorAll(linksSelector);
    const panels = document.querySelectorAll(panelsSelector);

    const handleLinkClick = function (e) {
      if (!this.classList.contains('active')) {
        links.forEach(link => link.classList.remove('active'));
        const activeLink = Array.from(links).find(link => link.getAttribute('href') === this.hash);
        activeLink.classList.add('active');

        panels.forEach(panel => panel.classList.remove('open'));
        const activePanel = document.querySelector(this.hash);
        activePanel.classList.add('open');
        activePanel.addEventListener('transitionend', function () {

          const targetElement = this;
          const scrollToTop = function () {
            const offsetTop = targetElement.getBoundingClientRect().top + window.pageYOffset;
            const startPosition = window.pageYOffset;
            const distance = offsetTop - startPosition;
            const duration = 1000;
            let startTime = null;

            const step = function (currentTime) {
              if (startTime === null) {
                startTime = currentTime;
              }
              const elapsedTime = currentTime - startTime;
              const progress = Math.min(elapsedTime / duration, 1);
              const easeProgress = easeOutCubic(progress);

              window.scrollTo(0, startPosition + distance * easeProgress);

              if (progress < 1) {
                requestAnimationFrame(step);
              }
            };

            requestAnimationFrame(step);
          };

          const easeOutCubic = function (t) {
            return 1 - Math.pow(1 - t, 3);
          };

          scrollToTop();

        }, { once: true });
      }
      e.preventDefault();
    };

    document.addEventListener('click', function (e) {
      if (e.target.matches(linksSelector)) {
        handleLinkClick.call(e.target, e);
      }
    });
  };

  toggle('.js-toggle-links', '.js-toggle-panels');


  // sliders
  var sliderKnife = new Swiper(".js-slider-knife", {
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      400: {
        slidesPerView: 2,
      },
      500: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 4,
      },
      1024: {
        slidesPerView: 5,
      }
    }
  });


}, false);
